// @ts-nocheck
// @ts-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  RouterProvider,
  createBrowserRouter,
  Outlet,
} from 'react-router-dom';
import { IconButton, styled } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider as Snackbar } from 'notistack';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import { loginSuccess } from './store/slices/UserSilcer';
import { isValueEmpty, initKeycloak } from './utils/Utils';
import useCurrentTheme from './Theme/useCurrentTheme';
import { initializeAxiosForKeycloak } from './utils/AxiosKeyCloak';
import FullscreenLoader from './components/Common/FullScreenLoader';
import { initializeAxios } from './utils/Axios';
import { getProtectedRoutes, publicRoutes } from './Routes';
import './App.css';
import SuspenseComponent from './components/Common/SuspenseComponent';
import ErrorBoundary from './components/Common/ErrorBoundary';
import useCommon from './hooks/useCommon';
import useThemeAppearance from './hooks/Configuration/useThemeAppearance';

const CloseSnackbarIconButton = ({ key, onClickSnackBarClose }) => {
  const onClick = useCallback(
    () => onClickSnackBarClose(key),
    [key, onClickSnackBarClose]
  );
  return (
    <IconButton onClick={onClick}>
      <CloseIcon
        sx={{
          color: (theme) => theme?.palette?.other?.white,
        }}
      />
    </IconButton>
  );
};

CloseSnackbarIconButton.propTypes = {
  onClickSnackBarClose: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
};

if (
  window.env.REACT_APP_KEYCLOAK_LOGIN &&
  !isValueEmpty(localStorage.getItem('REALM_ID'))
) {
  if (isValueEmpty(localStorage.getItem('access_token'))) {
    initKeycloak().then(() => {
      initializeAxiosForKeycloak();
    });
  } else {
    initKeycloak();
    initializeAxiosForKeycloak();
  }
} else {
  initializeAxios();
}

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ isLoggedIn }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /**
   * Handles the Keycloak authentication event.
   *
   * @callback handleKeycloakEvent
   * @param {Object} event - The event object containing Keycloak authentication details.
   */
  const handleKeycloakEvent = React.useCallback(
    (event) => {
      const { auth, keycloakConfig } = event.detail;
      const keycloakUserData = keycloakConfig.tokenParsed;
      const userData = {
        username: keycloakUserData.preferred_username,
        email: keycloakUserData.email,
        id: keycloakUserData.sub,
      };

      window.KEYCLOAK_CONFIG = keycloakConfig;
      dispatch(
        loginSuccess({
          isAuthenticate: auth,
          token: keycloakConfig.token,
          refresh: keycloakConfig.refreshToken,
          user: userData,
        })
      );
      setLoading(false);

      if (location?.pathname === '/org') {
        navigate('/dashboard?value=0');
      }
    },

    [dispatch, loginSuccess, setLoading]
  );

  useEffect(() => {
    document.addEventListener('KEYCLOAK_LOGIN_SUCCESS', handleKeycloakEvent);
    if (window.envREACT_APP_KEYCLOAK_LOGIN !== true) {
      return () => {
        document.removeEventListener(
          'KEYCLOAK_LOGIN_SUCCESS',
          handleKeycloakEvent
        );
      };
    }
    return () => {};
  }, []);

  if (!isLoggedIn) {
    if (
      window.env.REACT_APP_KEYCLOAK_LOGIN &&
      !isValueEmpty(localStorage.getItem('REALM_ID')) &&
      loading
    ) {
      return <h5>Loading...</h5>;
    }
    if (
      window.env.REACT_APP_KEYCLOAK_LOGIN &&
      isValueEmpty(localStorage.getItem('REALM_ID'))
    ) {
      return <Navigate to="/org" state={{ from: location }} replace />;
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <Outlet />;
};
const SnackbarProvider = styled(Snackbar)`
  &.SnackbarItem-contentRoot {
    width: 345px;
  }
  & .SnackbarItem-message {
    margin-right: 27px;
    word-break: break-word;
  }
  & .SnackbarItem-action {
    position: absolute;
    right: 0;
    top: 5px;
    margin-right: 5px;
    padding: 0;
  }
  &.SnackbarItem-variantSuccess {
    background: ${(p) => p.theme.palette.success.main};
  }
  &.SnackbarItem-variantError {
    background: ${(p) => p.theme.palette.error.main};
  }
  &.SnackbarItem-variantWarning {
    background: ${(p) => p.theme.palette.warning.main};
  }
  &.SnackbarItem-variantInfo {
    background: ${(p) => p.theme.palette.info.main};
  }
`;

function App() {
  const { isAuthenticate } = useSelector((state) => state.users);
  const theme = useCurrentTheme('light-theme');
  const notistackRef = useRef();
  const { users } = useSelector((state) => state);
  const { permissions } = users;

  const { common } = useCommon();
  const { getCurrentTheme, currentSavedTheme } = useThemeAppearance();

  useEffect(() => {
    if (isAuthenticate) {
      setTimeout(() => {
        getCurrentTheme();
      }, [500]);
    }
  }, [common.triggerThemeChange, isAuthenticate]);

  const [applyTheme, setApplyTheme] = useState(() => {
    const newTheme = currentSavedTheme?.saved_theme;
    if (newTheme) {
      return createTheme(merge(theme, newTheme));
    }
    return theme;
  });

  useEffect(() => {
    const newTheme = currentSavedTheme?.saved_theme;
    if (newTheme) {
      const currentTheme = createTheme(merge(theme, newTheme));
      setApplyTheme(currentTheme);
    } else {
      setApplyTheme(theme);
    }
  }, [currentSavedTheme]);

  useEffect(() => {
    // @ts-ignore
    window.enqueueSnackbar = notistackRef.current.enqueueSnackbar;
    window.closeSnackbar = notistackRef.current.closeSnackbar;
  }, []);

  /**
   * @function  onClickSnackBarClose
   * @description when click on cross button of toast dialog it will close the snack bar
   */
  const onClickSnackBarClose = useCallback((key) => {
    notistackRef.current.closeSnackbar(key);
  }, []);

  const router = React.useMemo(
    () =>
      createBrowserRouter([
        ...publicRoutes,
        {
          path: '/',
          element: <PrivateRoute isLoggedIn={isAuthenticate} />,
          children: getProtectedRoutes(permissions),
          errorElement: <ErrorBoundary />,
        },
      ]),
    [permissions, isAuthenticate]
  );

  const renderActionComponent = useCallback(
    (key) => (
      <CloseSnackbarIconButton
        key={key}
        onClickSnackBarClose={onClickSnackBarClose}
      />
    ),
    [onClickSnackBarClose]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={applyTheme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          hideIconVariant
          preventDuplicate
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={5000}
          // eslint-disable-next-line react/no-unstable-nested-components
          action={renderActionComponent}
        >
          <FullscreenLoader />
          <SuspenseComponent>
            <RouterProvider router={router} />
          </SuspenseComponent>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
